import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../../environments/environment';

declare const gtag: Function;
declare const ym: Function;
declare const fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() {

  }

  public reportVisit(url: string) {
    if (environment.analytics.google.enabled) {
      gtag('event', 'page_view', {
        page_path: url,
      });
    }

    if (environment.analytics.yandex.enabled) {
      ym(environment.analytics.yandex.id, 'hit', url);
    }
  }

  public setUserId(userId: number) {
    Sentry.setUser({
      id: userId.toString(),
    });

    if (environment.analytics.google.enabled) {
      gtag('config', environment.analytics.google.id, {
        user_id: userId.toString(),
      });
    }

    if (environment.analytics.yandex.enabled) {
      ym(environment.analytics.yandex.id, 'setUserID', userId.toString());
    }

    if (environment.analytics.facebook.enabled) {
      fbq('track', 'PageView');
    }
  }

  public setBalance(balance: Number) {

  }

  public reportTaskView(taskId: Number, taskType: String, taskName: String, reward: Number) {
    this.reportEvent('task_view', {
      id: taskId,
      type: taskType,
      name: taskName,
      reward: reward,
    });
  }

  public reportTaskStart(taskId: Number, taskType: String, taskName: String, reward: Number) {
    this.reportEvent('task_start', {
      id: taskId,
      type: taskType,
      name: taskName,
      reward: reward,
    });
  }

  public reportTaskAppLaunch(taskId: Number, taskType: String, taskName: String, reward: Number) {
    this.reportEvent('task_app_launch', {
      id: taskId,
      type: taskType,
      name: taskName,
      reward: reward,
    });
  }

  public reportCopyPromoCode(promoCode: String) {
    this.reportEvent('copy_promo_code', {
      promo_code: promoCode,
    });
  }

  public reportStartSignIn(method: String) {
    this.reportEvent('start_sign_in', {
      method: method,
    });
  }

  public reportSignIn() {
    this.reportEvent('sign_in');
  }

  public reportSignUp() {
    this.reportEvent('sign_up');
  }

  private reportEvent(name: String, params: Object = {}) {
    if (environment.analytics.google.enabled) {
      gtag('event', name, params);
    }

    if (environment.analytics.yandex.enabled) {
      ym(environment.analytics.yandex.id, 'reachGoal', name, params);
    }

    if (environment.analytics.facebook.enabled) {
      fbq('trackCustom', name, params);
    }
  }
}
