import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlService } from '../../../shared/api/service/api-url.service';
import { map, Observable, of, take } from 'rxjs';
import { UserProfile } from '../models/UserProfile';
import { StorageService } from '../../../shared/storage/service/storage.service';
import { AnalyticsService } from '../../../shared/analytics/analytics.service';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
    private storageService: StorageService,
    private analyticsService: AnalyticsService,
  ) {
  }

  getProfile(): Observable<UserProfile> {
    return this.httpClient
      .get<UserProfile>(this.apiUrlService.getApiUrl('/users/'))
      .pipe(map((userProfile) => {
        this.saveUserProfile(userProfile);

        return userProfile;
      }))
      .pipe(take(1));
  }

  getUserId(): Observable<Number> {
    const storedUserId = this.storageService.get('user_id');
    if (storedUserId != null) {
      return of(Number(storedUserId)).pipe(take(1));
    }

    return this.getProfile()
      .pipe(map((userProfile) => {
        this.saveUserProfile(userProfile);

        return userProfile.id;
      }))
      .pipe(take(1));
  }

  private saveUserProfile(userProfile: UserProfile) {
    this.analyticsService.setUserId(userProfile.id);
    this.analyticsService.setBalance(userProfile.balance);

    this.storageService.set('user_id', userProfile.id.toString());
  }
}
