<app-toolbar i18n-title title="Log in" [showBack]=false *ngIf="webViewType == webViewTypeEnum.None"></app-toolbar>

<div class="container-fluid" *ngIf="screenState == screenStateType.Content">
  <div class="row mt-5" *ngIf="authType == firebaseAuthType.Google && webViewType == webViewTypeEnum.None">
    <div class="col d-flex align-items-center">
      <button
        mat-fab
        extended
        color="primary"
        class="w-100 mb-2 task-action-button"
        (click)="loginWithGoogle()">
        <mat-icon>login</mat-icon>
        <ng-container i18n>Sign in with Google</ng-container>
      </button>
    </div>
  </div>

  <div class="row mt-5" *ngIf="authType == firebaseAuthType.Apple && webViewType == webViewTypeEnum.None">
    <div class="col d-flex align-items-center">
      <button
        mat-fab
        extended
        color="primary"
        class="w-100 mb-2 task-action-button"
        (click)="loginWithApple()">
        <mat-icon>login</mat-icon>
        <ng-container i18n>Sign in with Apple</ng-container>
      </button>
    </div>
  </div>
</div>

<div class="container" *ngIf="webViewType != webViewTypeEnum.None">

  <div class="row" *ngIf="webViewType == webViewTypeEnum.Facebook">
    <div class="col">
      <div class="row">
        <div class="col d-flex flex-row justify-content-end">
          <img class="open-in-chrome-arrow align-self-end"
               src="assets/landing/img/open-in-browser/open-in-chrome-arrow.svg" alt="Open in chrome arrow">
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <p i18n>This page can't work inside the embedded browser.</p>
          <p i18n>Please tap on <strong>three dots</strong> icon and then on <strong>«Open in Chrome»</strong> to
            continue</p>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <img class="img-fluid" src="assets/landing/img/open-in-browser/messenger-android-en.jpg" alt="Open in Chrome">
        </div>
      </div>
    </div>
  </div>
</div>

<app-loader [screenState]="screenState"></app-loader>
