import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { getLogger } from '../log/log-utils';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private logger = getLogger('NavigationService');

  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
    private analyticsService: AnalyticsService,
  ) {
    this.logger.debug('Created');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;

        this.logger.debug(`NavigationEnd: ${ url }`);
        this.history.push(url);

        analyticsService.reportVisit(url);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/tasks');
    }
  }
}
