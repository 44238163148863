import { Component, Input } from '@angular/core';
import { NavigationService } from '../../navigation/navigation.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input()
  title: string = '';

  @Input()
  showBack: boolean = false;

  constructor(
    private navigationService: NavigationService,
  ) {
  }

  onBackClick() {
    this.navigationService.back();
  }
}
