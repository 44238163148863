import { Component, Input } from '@angular/core';
import { ScreenState } from '../state/screen-state';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  screenStateType = ScreenState;

  @Input()
  screenState: ScreenState | undefined;
}
