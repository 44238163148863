import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { ErrorNotFoundComponent } from './features/errors/error-not-found/error-not-found.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [NoAuthGuard,],
    loadChildren: () => import('./features/auth/auth-routing.module').then(m => m.AuthRoutingModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard,],
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'balance',
    canActivate: [AuthGuard,],
    loadChildren: () => import('./features/balance/balance.module').then(m => m.BalanceModule)
  },
  {
    path: 'tasks',
    canActivate: [AuthGuard,],
    loadChildren: () => import('./features/tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: 'referrals',
    canActivate: [AuthGuard,],
    loadChildren: () => import('./features/referrals/referrals.module').then(m => m.ReferralsModule)
  },
  {
    path: 'policies',
    canActivate: [NoAuthGuard,],
    loadChildren: () => import('./features/policies/policies.module').then(m => m.PoliciesModule),
  },
  {
    path: '',
    canActivate: [NoAuthGuard,],
    loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    component: ErrorNotFoundComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
