import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

if (environment.sentry.enabled) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', environment.backend.url],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    environment: environment.name,
    tracesSampleRate: environment.sentry.tracesSampleRate,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));

if (environment.production) {
  enableProdMode();
}
