import { Component, isDevMode, OnInit } from '@angular/core';
import { DevicePingService } from './features/device/services/device-ping.service';
import { getLogger } from './shared/log/log-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { ReferralService } from './shared/referral/referral.service';
import { slideInAnimation } from './shared/ui/animations/route-animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation,
  ]
})
export class AppComponent implements OnInit {

  private logger = getLogger('AppComponent');

  title = 'Zilbo';

  constructor(
    private devicePingService: DevicePingService,
    private referralService: ReferralService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.logger.debug(`Initializing; dev mode = ${ isDevMode() }`);

    this.sendPing();
    this.handlePromoCodeFromUrl();

    window.addEventListener('load', function () {
      try {
        let cc = window as any;

        cc.cookieconsent.initialise({
          palette: {
            popup: {
              background: '#164969'
            },
            button: {
              background: '#ffe000',
              text: '#164969'
            }
          },
          theme: 'classic',
          content: {
            message: 'We use cookies to ensure you get the best experience',
            dismiss: 'OK!',
            link: 'Learn more',
            href: 'https://zilbo.app/policies/privacy',
          }
        });
      } catch (e) {
        // this.logger.error(e, 'Failed to show cookies consent');
      }
    });
  }

  private sendPing(): void {
    this.devicePingService.sendPing();
  }


  private handlePromoCodeFromUrl() {
    this.activatedRoute
      .queryParams
      .pipe(map(params => params['promo_code']))
      .pipe(filter(x => x !== undefined))
      .subscribe({
        next: promoCode => {
          this.logger.debug(`Got promo code: ${ promoCode }`);

          this.referralService.setReferrerPromoCode(promoCode);
        },
        error: err => {
          this.logger.error(err, 'Failed to get promo code from URL');
        }
      });
  }
}
