import { ScreenState } from './screen-state';
import { ApiFieldsError, ApiGeneralError, RequestError } from '../../api/models/Errors';
import { ApiErrorService } from '../../api/service/api-error.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error/error-dialog/error-dialog.component';

export abstract class ScreenStateComponent {

  screenStateType = ScreenState;
  screenState = ScreenState.Content;

  error: RequestError | undefined;

  protected constructor(
    private apiErrorService: ApiErrorService,
    private dialog: MatDialog,
  ) {
  }

  protected setLoadingState() {
    this.screenState = ScreenState.Loading;
    this.error = undefined;
  }

  protected setContentState() {
    this.screenState = ScreenState.Content;
    this.error = undefined;
  }

  protected setErrorState(error: any | null) {
    if (error == null) {
      this.setContentState();
      return;
    }

    error = this.apiErrorService.processApiError(error);

    if (error instanceof ApiFieldsError) {
      this.setContentState();
      this.handleFieldsError(error);
      return;
    }

    if (error instanceof ApiGeneralError) {
      console.log('ApiGeneralError');
      this.setContentState();

      if (!this.handleApiGeneralError(error)) {
        console.log('showErrors');
        this.showErrors(error.errors);
      }

      return;
    }

    this.error = error;
    this.screenState = ScreenState.Error;
  }

  protected handleFieldsError(error: ApiFieldsError): void {
  }

  protected handleApiGeneralError(error: ApiGeneralError): boolean {
    return false;
  }

  private showErrors(errors: string[]) {
    for (const error of errors.reverse()) {
      this.showErrorDialog(error);
    }
  }

  private showErrorDialog(error: string) {
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {error: error},
    });
  }

  protected handleRetry(): void {
    if (!this.onRetry()) {
      // Just reload the page
      window.location.reload();
    }
  }

  protected onRetry(): boolean {
    return false;
  }
}
