import { Injectable } from '@angular/core';
import { StorageService } from "../../../shared/storage/service/storage.service";


const AUTH_TOKEN = "auth-token"

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private storageService: StorageService,
  ) {
  }

  isAuthenticated(): boolean {
    return this.getAuthToken() != null;
  }

  getAuthToken(): string | null {
    return this.storageService.get(AUTH_TOKEN)
  }

  saveAuthToken(token: string | null): void {
    if (token != null) {
      this.storageService.set(AUTH_TOKEN, token)
    } else {
      this.storageService.remove(AUTH_TOKEN)
    }
  }
}
