import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../../features/auth/services/auth.service";

const TOKEN_HEADER = 'Token'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getAuthToken();
    if (token != null) {
      req = req.clone({
        headers: req.headers.append(TOKEN_HEADER, token)
      })
    }

    return next.handle(req)
  }
}
