import { Component, OnInit } from '@angular/core';
import { FirebaseAuthType } from '../models/FirebaseAuthType';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { FirebaseAuthService } from '../services/firebase-auth.service';
import { DevicePingService } from '../../device/services/device-ping.service';
import { getLogger } from '../../../shared/log/log-utils';
import { filter, map, take } from 'rxjs';
import { DeviceOs } from '../../../shared/device/models/DeviceOs';
import { DeviceService } from '../../../shared/device/service/device.service';
import { ProfileService } from '../../profile/services/profile.service';
import { ScreenStateComponent } from '../../../shared/ui/state/ScreenStateComponent';
import { ApiErrorService } from '../../../shared/api/service/api-error.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { AnalyticsService } from '../../../shared/analytics/analytics.service';
import { WebviewService } from '../../../shared/webview/service/webview.service';
import { WebViewType } from '../../../shared/webview/model/WebViewType';

@Component({
  selector: 'app-firebase',
  templateUrl: './firebase.component.html',
  styleUrls: ['./firebase.component.scss']
})
export class FirebaseComponent extends ScreenStateComponent implements OnInit {

  private logger = getLogger('FirebaseComponent');

  firebaseAuthType = FirebaseAuthType;
  webViewTypeEnum = WebViewType;

  authType: FirebaseAuthType | undefined;
  webViewType: WebViewType = WebViewType.None;

  constructor(
    private router: Router,
    private auth: Auth,
    private firebaseAuthService: FirebaseAuthService,
    private devicePingService: DevicePingService,
    private deviceService: DeviceService,
    private profileService: ProfileService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private webviewService: WebviewService,
    private activatedRoute: ActivatedRoute,
    apiErrorService: ApiErrorService,
    dialog: MatDialog,
  ) {
    super(apiErrorService, dialog);
  }

  ngOnInit(): void {
    this.logger.debug('Initialized');

    this.checkForWebView();

    this.initAuthType();
    this.redirectIfAuthenticated();

    if (this.webViewType == WebViewType.None) {
      this.logger.debug('Not a web view, will check for auto start');
      // this.checkIfAutoStart();
    }
  }

  private checkForWebView() {
    this.webViewType = this.webviewService.getWebViewType();
    this.logger.debug(`Web view type = ${ this.webViewType }`);

    if (this.webViewType != WebViewType.None) {
      const os = this.deviceService.getDeviceOs();

      let newUrl = '';

      switch (os) {
        case DeviceOs.Android:
          newUrl = `intent:${ window.location.href }#Intent;end`;
          break;

        case DeviceOs.Ios:
          newUrl = `com-apple-mobilesafari-tab:${ window.location.href }`;
          break;

        default:
          this.logger.info('Unknown OS, will not redirect');
          break;
      }

      if (newUrl != '') {
        this.logger.debug(`Trying to open ${ newUrl }`);
        window.location.href = newUrl;
      }
    }
  }

  private redirectIfAuthenticated() {
    if (this.authService.isAuthenticated()) {
      this.router.navigateByUrl('/tasks');
    }
  }

  private checkIfAutoStart() {
    this.activatedRoute
      .queryParams
      .pipe(map(params => params['autologin']))
      .pipe(filter(x => x !== undefined))
      .subscribe({
        next: flag => {
          this.logger.debug(`Got auto login flag: ${ flag }`);

          switch (this.authType) {
            case FirebaseAuthType.Google:
              this.loginWithGoogle();
              break;

            case FirebaseAuthType.Apple:
              this.loginWithApple();
              break;

            default:
              this.logger.info('Unknown auth type, will not autostart');
          }
        },
        error: err => {
          this.logger.error(err, 'Failed to get auto login flag from URL');
        }
      });
  }

  private initAuthType() {
    const os = this.deviceService.getDeviceOs();
    switch (os) {
      case DeviceOs.Android:
        this.authType = FirebaseAuthType.Google;
        break;
      case DeviceOs.Ios:
        this.authType = FirebaseAuthType.Apple;
        break;
      case DeviceOs.Other:
        this.router.navigateByUrl('/auth/continue-on-mobile');
    }
  }

  async loginWithGoogle() {
    this.setLoadingState();

    this.analyticsService.reportStartSignIn('google');

    const provider = new GoogleAuthProvider();

    try {
      let credential = await signInWithPopup(this.auth, provider);
      let token = await credential.user.getIdToken();

      this.logger.debug(`Email: ${ credential.user.email }, token: ${ token }`);

      this
        .firebaseAuthService
        .authenticate(token)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.logger.info('Authenticated with Google');

            this.handleLoginDone();
          },
          error: (error) => {
            this.logger.error(error);

            this.setContentState();
          }
        });
    } catch (e) {
      this.logger.error(e, 'Failed to authenticate');
      this.setContentState();
    }
  }

  async loginWithApple() {
    this.setLoadingState();

    this.analyticsService.reportStartSignIn('apple');

    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    let credential = await signInWithPopup(this.auth, provider);
    let token = await credential.user.getIdToken();
    this.logger.debug(`Email: ${ credential.user.email }, token: ${ token }`);

    this
      .firebaseAuthService
      .authenticate(token)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.logger.info('Authenticated with Apple');

          this.handleLoginDone();
        },
        error: (error) => {
          this.logger.error(error);

          this.setContentState();
        }
      });
  }

  private handleLoginDone() {
    this.devicePingService.sendPing();
    this.loadProfile();
  }

  private loadProfile() {
    this.profileService
      .getProfile()
      .pipe(take(1))
      .subscribe({
        next: profile => {
          this.logger.debug(`Loaded profile: ${ JSON.stringify(profile) }`);

          if (profile.has_referrer) {
            this.router.navigateByUrl('/tasks');
          } else {
            this.router.navigateByUrl('/referrals/apply-referral-code');
          }
        },
        error: err => {
          this.logger.error(err, 'Failed to load profile');

          this.setErrorState(err);
        }
      });
  }

  protected readonly window = window;
}
