import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirebaseComponent } from './firebase/firebase.component';
import { ContinueOnMobileComponent } from './continue-on-mobile/continue-on-mobile.component';

const routes: Routes = [
  {path: 'firebase', component: FirebaseComponent,},
  {path: 'continue-on-mobile', component: ContinueOnMobileComponent,},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
