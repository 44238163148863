import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {

  constructor() {
  }

  private getBaseBackendUrl(): string {
    return environment.backend.url;
  }

  private getBaseApiUrl(): string {
    return `${ this.getBaseBackendUrl() }/api/v1`;
  }

  getApiUrl(path: string): string {
    if (!path.startsWith('/')) {
      throw new Error(`Path "${ path }" doesn't start with /`);
    }

    return this.getBaseApiUrl() + path;
  }

  getUrl(path: string): string {
    if (!path.startsWith('/')) {
      throw new Error(`Path "${ path }" doesn't start with /`);
    }

    return this.getBaseBackendUrl() + path;
  }
}
