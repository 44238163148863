<app-toolbar i18n-title title="Continue on mobile" [showBack]=false></app-toolbar>

<div class="container-fluid">
  <div class="row mt-2">
    <div class="col d-flex justify-content-center">
      <h5 class="mat-headline-6" i18n>Open this page on your phone</h5>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col d-flex justify-content-center">
      <p class="mat-body-1">
        <ng-container i18n>Enter this address:</ng-container>
        <span class="continue-on-mobile-url">{{currentUrl}}</span></p>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col d-flex justify-content-center">
      <h6 class="mat-headline-6" i18n>Or just scan this QR with your phone's camera:</h6>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col d-flex justify-content-center">
      <qrcode [qrdata]="currentUrl" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
  </div>
</div>
