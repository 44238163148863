import { Injectable } from '@angular/core';
import { getLogger } from '../../log/log-utils';
import { WebViewType } from '../model/WebViewType';

@Injectable({
  providedIn: 'root'
})
export class WebviewService {

  private logger = getLogger('WebviewService');


  constructor() {
  }

  getWebViewType(): WebViewType {
    const userAgent = window.navigator.userAgent.toLowerCase();

    this.logger.debug(`User agent = ${ userAgent }`);

    if (userAgent.includes('instagram')) {
      return WebViewType.Instagram;
    } else if (userAgent.includes('fb_iab')) {
      return WebViewType.Facebook;
    } else {
      return WebViewType.None;
    }
  }
}
