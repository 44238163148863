import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ApiUrlService } from "../../../shared/api/service/api-url.service";
import { AuthService } from "../../auth/services/auth.service";
import { getLogger } from "../../../shared/log/log-utils";
import { take } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DevicePingService {

  private logger = getLogger("DevicePingService");

  constructor(
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
    private authService: AuthService,
  ) {
  }

  sendPing(): void {
    if (!this.authService.isAuthenticated()) {
      this.logger.debug('Not authenticated, will not send ping');
      return;
    }

    this.httpClient
      .post(
        this.apiUrlService.getApiUrl('/users/ping/'),
        {}
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.logger.debug('Sent ping');
        },
        error: (error) => {
          this.logger.error(error, 'Failed to send ping');
        }
      })
  }
}
