import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApiUrlService } from '../../../shared/api/service/api-url.service';
import { HttpClient } from '@angular/common/http';
import { map, Observable, take, tap } from 'rxjs';
import { getLogger } from '../../../shared/log/log-utils';
import { AuthResponse } from '../models/AuthResponse';
import { AuthRequest } from '../models/AuthRequest';
import { AnalyticsService } from '../../../shared/analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {

  private logger = getLogger('FirebaseAuthService');

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private apiUrlService: ApiUrlService,
    private analyticsService: AnalyticsService,
  ) {
  }

  authenticate(firebaseToken: string): Observable<void> {
    const request: AuthRequest = {
      token: firebaseToken,
    };

    return this.httpClient
      .post<AuthResponse>(
        this.apiUrlService.getApiUrl('/users/auth/firebase/'),
        request
      )
      .pipe(take(1))
      .pipe(tap(response => {
        this.authService.saveAuthToken(response.token);
        if (response.is_user_created) {
          this.analyticsService.reportSignUp();
        } else {
          this.analyticsService.reportSignIn();
        }
      }))
      .pipe(map(() => void 0));
  }
}
