import { Component, OnInit } from '@angular/core';
import { getLogger } from '../../../shared/log/log-utils';
import { DeviceService } from '../../../shared/device/service/device.service';
import { DeviceOs } from '../../../shared/device/models/DeviceOs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-continue-on-mobile',
  templateUrl: './continue-on-mobile.component.html',
  styleUrls: ['./continue-on-mobile.component.scss']
})
export class ContinueOnMobileComponent implements OnInit {

  private logger = getLogger('ContinueOnMobileComponent');

  currentUrl = '';

  constructor(
    private deviceService: DeviceService,
    private router: Router,
  ) {
    this.logger.debug('Initialized');
  }

  ngOnInit(): void {
    this.currentUrl = window.location.href;

    this.initDeviceType();
  }

  private initDeviceType() {
    const os = this.deviceService.getDeviceOs();
    switch (os) {
      case DeviceOs.Android:
      case DeviceOs.Ios:
        this.router.navigateByUrl('/auth/firebase');
        break;
    }
  }
}
