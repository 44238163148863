import { Injectable } from '@angular/core';
import { StorageService } from '../storage/service/storage.service';
import { ApiUrlService } from '../api/service/api-url.service';
import { Observable, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PromoCode } from '../../features/profile/models/UserProfile';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  constructor(
    private storageService: StorageService,
    private httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
  ) {
  }

  getReferrerPromoCode(): string | null {
    return this.storageService.get('referrer_promo_code');
  }

  setReferrerPromoCode(promoCode: string) {
    this.storageService.set('referrer_promo_code', promoCode);
  }

  shouldIgnorePromoCode(): boolean {
    return this.storageService.get('should_ignore_promo_code') === 'true';
  }

  setShouldIgnorePromoCode() {
    this.storageService.set('should_ignore_promo_code', 'true');
  }

  applyPromoCode(promoCode: string): Observable<void> {
    const data: PromoCode = {
      'code': promoCode,
    };

    return this.httpClient
      .post<void>(
        this.apiUrlService.getApiUrl('/users/referral/apply/'),
        data
      )
      .pipe(take(1));
  }
}
