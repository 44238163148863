import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { getLogger } from '../../log/log-utils';
import {
  ApiError,
  ApiFieldError,
  ApiFieldsError,
  ApiGeneralError,
  NetworkError,
  RequestError,
  UnknownRequestError
} from '../models/Errors';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {

  private logger = getLogger('ApiErrorService');


  constructor() {
  }

  processApiError(error: any | null): RequestError {
    if (error === null) {
      this.logger.warn('Null error');
      return new UnknownRequestError();
    }

    if (error instanceof HttpErrorResponse) {
      this.logger.debug('HTTP Error');

      const status = error.status || 0;

      if (status >= 500) {
        this.logger.debug('Server-side Error');

        return new ApiError(status);
      }

      if (error.error instanceof Array && error.error.every(it => typeof it === 'string')) {
        this.logger.debug('Client-side general error');

        return new ApiGeneralError(status, error.error);
      }

      if (
        error.error instanceof Object &&
        Object.values(error.error).every(it => it instanceof Array) &&
        (Object.values(error.error).every(array => (array as Array<any>).every(it => typeof it === 'string')) ||
          Object.values(error.error).length == 0)
      ) {
        this.logger.debug('Client-side fields error');

        const keys = Object.keys(error.error);
        const fieldsErrors: ApiFieldError[] = keys.map(key => new ApiFieldError(key, error.error[key]));

        return new ApiFieldsError(status, fieldsErrors);
      }

      return new NetworkError();
    }

    this.logger.debug(`Unknown error: ${ typeof error }`);

    return new UnknownRequestError();
  }
}
