import { Injectable } from '@angular/core';
import { Platform } from "@angular/cdk/platform";
import { DeviceOs } from "../models/DeviceOs";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private platform: Platform,
  ) {
  }

  getDeviceOs(): DeviceOs {
    if (this.platform.ANDROID) {
      return DeviceOs.Android;
    } else if (this.platform.IOS) {
      return DeviceOs.Ios;
    } else {
      return DeviceOs.Other;
    }
  }

  isAndroid(): boolean {
    return this.getDeviceOs() == DeviceOs.Android;
  }

  isIos(): boolean {
    return this.getDeviceOs() == DeviceOs.Ios;
  }
}
