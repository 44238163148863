import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule } from '@angular/forms';
import { FirebaseComponent } from './firebase/firebase.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ContinueOnMobileComponent } from './continue-on-mobile/continue-on-mobile.component';
import { QRCodeModule } from 'angularx-qrcode';
import { UiModule } from '../../shared/ui/ui.module';


@NgModule({
  declarations: [
    FirebaseComponent,
    ContinueOnMobileComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    QRCodeModule,
    UiModule,
  ]
})
export class AuthModule {
}
