import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BottomNavBarComponent } from './bottom-nav-bar/bottom-nav-bar.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorComponent } from './error/error.component';
import { ErrorDialogComponent } from './error/error-dialog/error-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    ToolbarComponent,
    BottomNavBarComponent,
    LoaderComponent,
    ErrorComponent,
    ErrorDialogComponent,
  ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        RouterLink,
        RouterLinkActive,
        MatProgressSpinnerModule,
        MatDialogModule,
    ],
  exports: [
    ToolbarComponent,
    BottomNavBarComponent,
    LoaderComponent,
    ErrorComponent,
  ]
})
export class UiModule {
}
