export const environment = {
  firebase: {
    projectId: 'ezmoney-42578',
    appId: '1:1090272320828:web:f54f13f6d245173cf85239',
    storageBucket: 'ezmoney-42578.appspot.com',
    apiKey: 'AIzaSyBJgK6O-fizUU371O05Kp0rlFFQk2W23G0',
    authDomain: 'ezmoney-42578.firebaseapp.com',
    messagingSenderId: '1090272320828',
    measurementId: 'G-0LQ5XS6RWT',
  },

  production: true,
  name: 'production',
  sentry: {
    enabled: true,
    dsn: 'https://84267d83974f4d1da87bedc5fa29acb2@o4504963625975808.ingest.sentry.io/4505029018386432',
    tracesSampleRate: 0.01,
    authToken: 'e6dfa9d080c5411693b91c3763bf7bf5c6deccb4979d405381de1eb4c4a7db10',
  },
  analytics: {
    google: {
      enabled: true,
      id: 'G-WWETNCY9LF',
    },
    yandex: {
      enabled: true,
      id: 93258942,
    },
    facebook: {
      enabled: true,
      id: '736577021299913',
    },
  },
  backend: {
    url: 'https://api.alkot.xyz',
  },
};
