export interface RequestError {
}

export class ApiError implements RequestError {
  status: number;

  isClient: boolean;
  isServer: boolean;

  constructor(status: number) {
    this.status = status;

    this.isClient = status < 500;
    this.isServer = status >= 500;
  }
}

export class ApiFieldError {
  field: string;
  errors: string[];

  constructor(field: string, errors: string[]) {
    this.field = field;
    this.errors = errors;
  }
}

export class ApiFieldsError extends ApiError {
  errors: ApiFieldError[];

  constructor(status: number, errors: ApiFieldError[]) {
    super(status);

    this.errors = errors;
  }
}

export class ApiGeneralError extends ApiError {
  errors: string[];

  constructor(status: number, errors: string[]) {
    super(status);

    this.errors = errors;
  }
}

export class NetworkError implements RequestError {
}

export class UnknownRequestError implements RequestError {
}
