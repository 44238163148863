
<div class="container">
  <div class="row mt-4">
    <div class="col d-flex flex-column justify-content-center align-items-center">
      <h2>Not found</h2>

      <p class="mt-2">
        Try to start from the <a href="/">Main page</a>
      </p>
    </div>
  </div>
</div>

